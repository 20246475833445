
// import SVGLogo from '../../assets/svg/logoRialto.svg';

export default {
  // components: {
  //   SVGLogo
  // },
  props: {
    data: {
      type: Object,
      default: null
    }
  },
  data() {
    return {
      muted: true
    };
  }
};
